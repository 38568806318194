import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { PersonKey } from '../../helpers/storage';

const ProtectedRoute = ({ ...props }: RouteProps) => {
  const [person] = useLocalStorage<PERScreen.Person>(PersonKey);

  if (!person) {
    return <Redirect to="/identify" />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
