import React from 'react';
import { Link } from 'react-router-dom';

// assets
import LogoUrl from '../../assets/logo.svg';

import styles from './styles.scss';

const Nav = () => (
  <nav className={styles.nav}>
    <Link to="/">
      <img className={styles.logo} src={LogoUrl} alt="Incon Health" />
    </Link>
    <h1>Screening</h1>
  </nav>
);

export default Nav;
