import React from 'react';
import { Helmet } from 'react-helmet';

import Message from '../../components/message';

import styles from './styles.scss';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Helmet title="Error! | Screening | Incon Health" />
          <div className={styles.error}>
            <h2>Oops! Something went wrong.</h2>
            <p>Please refresh the page. If the error persists, please contact support.</p>
            <Message error>{this.state.errorMessage}</Message>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
