import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMount, useLocalStorage, useAsyncFn } from 'react-use';

// helpers
import { PersonKey, DailyScreeningKey } from '../helpers/storage';

// api
import { getPersonByIdAsync, getLatestScreeningAsync } from '../api/person';

// components
import ProtectedRoute from '../components/protected-route';
import Loader from '../components/loader';
import Layout from '../components/layout';

// lazy loaded containers
const Home = lazy(() => import('./home'));
const Identify = lazy(() => import('./identify'));
const Covid = lazy(() => import('./covid-19'));
const CovidTerms = lazy(() => import('./covid-19/terms'));
const Health = lazy(() => import('./health'));
const NotFound = lazy(() => import('./not-found'));

const App = () => {
  const [storedPerson, setStoredPerson] = useLocalStorage<PERScreen.Person>(PersonKey);
  const [, setLastScreening] = useLocalStorage<PERScreen.DailyScreeningState>(DailyScreeningKey);

  const [{ loading: fetchingUser }, fetchUser] = useAsyncFn(async (personId: number) => {
    if (storedPerson) {
      const updatedUser = await getPersonByIdAsync(personId);
      setStoredPerson(updatedUser);

      const lastScreeningResult = await getLatestScreeningAsync(personId);
      if (lastScreeningResult) {
        setLastScreening({
          fitForWork: lastScreeningResult.outcomeTypeId === 0,
          lastScreened: lastScreeningResult.recordDate
        });
      }
    }
  });

  useMount(() => {
    if (storedPerson) {
      if (navigator.onLine) {
        fetchUser(storedPerson.id);
      }
    }
  });

  return (
    <Layout>
      {fetchingUser ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <Switch>
            <ProtectedRoute path="/" component={Home} exact />
            <ProtectedRoute path="/covid-19/terms" component={CovidTerms} exact />
            <ProtectedRoute path="/covid-19" component={Covid} exact />
            <Route path="/identify" component={Identify} exact />
            <Route path="/health" component={Health} exact />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      )}
    </Layout>
  );
};

export default App;
