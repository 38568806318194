import 'whatwg-fetch';

const mergeHeaders = (body: RequestInit, headers: HeadersInit): RequestInit => ({
  ...body,
  headers: {
    ...body.headers,
    ...headers
  }
});

const Fetch = async (input: RequestInfo, body?: RequestInit) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  const requestBody = mergeHeaders(body || {}, headers);

  try {
    const response = await fetch(input, requestBody);

    if (response.ok) {
      const apiResponse: PERScreen.ApiResponse = await response.json();
      return apiResponse.data;
    } else {
      const apiError: PERScreen.ApiError = await response.json();
      throw new Error(apiError.message);
    }
  } catch (ex) {
    throw new Error('Unable to reach API');
  }
};

export default Fetch;
