import Fetch from '../helpers/fetch';

export const getPersonByIdOrPassportAsync = async (identifier: string, isPassport: boolean) => {
  const key = isPassport ? 'passportNumber' : 'idNumber';

  const response = await Fetch('/api/SelfScreen/PersonFilter', {
    method: 'POST',
    body: JSON.stringify({
      id: 0,
      [key]: identifier
    })
  });

  return response as PERScreen.Person;
};

export const getPersonByIdAsync = async (id: number) => {
  const response = await Fetch('/api/SelfScreen/PersonFilter', {
    method: 'POST',
    body: JSON.stringify({
      id
    })
  });

  return response as PERScreen.Person;
};

export const updatePersonAsync = async (person: PERScreen.PersonUpdate) => {
  const response = await Fetch('/api/SelfScreen/PersonUpdate', {
    method: 'PUT',
    body: JSON.stringify(person)
  });

  return response as boolean;
};

export const getLatestScreeningAsync = async (personId: number) => {
  const response = await Fetch(`/api/SelfScreen/Person/${personId}/LatestQuestionnaire`);
  return response as PERScreen.COVIDQuestionnaire;
};
